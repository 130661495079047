import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {Chip, CircularProgress, Divider} from "@mui/material";
import { ReactComponent as CesLogo } from './assets/images/ces_logo.svg';
import { ReactComponent as Happy } from "./assets/images/emoji_happy.svg";
import { ReactComponent as Neutral } from "./assets/images/emoji_neutral.svg";
import { ReactComponent as Surprised } from "./assets/images/emoji_surprised.svg";
import { ReactComponent as Angry } from "./assets/images/emoji_angry.svg";
import { ReactComponent as Disgust } from "./assets/images/emoji_disgust.svg";
import { ReactComponent as Fearful } from "./assets/images/emoji_fearful.svg";
import { ReactComponent as Sad } from "./assets/images/emoji_sad.svg";
import { ReactComponent as CiLogo } from "./assets/images/ci_logo.svg";
import { ReactComponent as IconDownload } from "./assets/images/icon_download.svg";
import { ReactComponent as IconExport } from "./assets/images/icon_export.svg";
import AlbumCover from "./assets/images/albumCover/music_01.png";

import happy from "./assets/images/happy.png";
import neutral from "./assets/images/neutral.png";
import angry from "./assets/images/angry.png";
import disgusted from "./assets/images/disgusted.png";
import fearful from "./assets/images/fearful.png";
import sad from "./assets/images/sad.png";
import surprised from "./assets/images/surprised.png";

// TODO: 칵테일 모듈과 인터페이스 정의 필요
interface UserData {
    feel: string; // 기분
    cocktail: string; // 칵테일 이름
    recommend: string; // 칵테일 추천 사유
    downloadUrl: string; // aws s3 또는 azure
    // music: string;
}

const PhotoCard: React.FC = () => {
    const [decodedJson, setDecodedJson] = useState({ feel: '', cocktail: '', recommend: '', downloadUrl: '' });
    const location = useLocation();
    const base64DecodeUnicode = (str: string): string => {
        // URL에서 사용하기 위해 변환했던 `-`와 `_`를 다시 `+`와 `/`로 복원
        const base64 = str.replace(/-/g, '+').replace(/_/g, '/');

        // 패딩 '='가 제거되었을 수 있으니 복원 필요 (Base64 문자열의 길이는 4의 배수여야 함)
        const padding = base64.length % 4 ? '='.repeat(4 - (base64.length % 4)) : '';
        const paddedBase64 = base64 + padding;

        // Base64 디코딩 수행
        const decodedBytes = atob(paddedBase64);

        // 디코드된 바이트를 다시 실제 문자열로 변환
        const percentEncodedStr = decodedBytes.split('').map(c =>
            '%' + c.charCodeAt(0).toString(16).padStart(2, '0')
        ).join('');

        // UTF-8 디코딩을 통해 최종 문자열을 복원
        const decodedStr = decodeURIComponent(percentEncodedStr);

        return decodedStr;
    };

    function getEmotionImage(expression: string) {
        console.log(`getEmotionImage = ${expression}`)
        let imgSrc = happy;
        if (expression.startsWith("happy")) {
            imgSrc = happy;
        } else if (expression.startsWith("neutral")) {
            imgSrc = neutral;
        } else if (expression.startsWith("angry")) {
            imgSrc = angry;
        } else if (expression.startsWith("disgusted")) {
            imgSrc = disgusted;
        } else if (expression.startsWith("fearful")) {
            imgSrc = fearful;
        } else if (expression.startsWith("sad")) {
            imgSrc = sad;
        } else if (expression.startsWith("surprised")) {
            imgSrc = surprised;
        }
        return imgSrc
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const data = queryParams.get('data');

        if (data) {
            console.log(`data = ${data}`);
            console.log("!!!!!!!!!!!!!!!!!!!")

            // const decodedJson: UserData = JSON.parse(atob(data));
            console.log(JSON.parse(base64DecodeUnicode(data)));
            const decoded = JSON.parse(base64DecodeUnicode(data));
            setDecodedJson(decoded);
        }
    }, []);

    // 테스트
    // const sampleJson = {
    //     "mood": "happy",
    //     "name": "Margarita",
    //     "recommend": "It's a simple, yet delightful cocktail that's known for its zesty and tangy flavors. A perfect balance of sweet, sour, and salty flavors, make Margarita an excellent choice to celebrate happiness.",
    //     "url": "http://image.sample"
    // }
    // const encoded = btoa(JSON.stringify(sampleJson));

    // happy : "eyJtb29kIjoiaGFwcHkiLCJuYW1lIjoiTWFyZ2FyaXRhIiwicmVjb21tZW5kIjoiSXQncyBhIHNpbXBsZSwgeWV0IGRlbGlnaHRmdWwgY29ja3RhaWwgdGhhdCdzIGtub3duIGZvciBpdHMgemVzdHkgYW5kIHRhbmd5IGZsYXZvcnMuIEEgcGVyZmVjdCBiYWxhbmNlIG9mIHN3ZWV0LCBzb3VyLCBhbmQgc2FsdHkgZmxhdm9ycywgbWFrZSBNYXJnYXJpdGEgYW4gZXhjZWxsZW50IGNob2ljZSB0byBjZWxlYnJhdGUgaGFwcGluZXNzLiIsInVybCI6Imh0dHA6Ly9pbWFnZS5zYW1wbGUifQ=="

    // let testEncoded : string = location.pathname;
    // let testEncoded : string = "eyJtb29kIjoiaGFwcHkiLCJuYW1lIjoiTWFyZ2FyaXRhIiwicmVjb21tZW5kIjoiSXQncyBhIHNpbXBsZSwgeWV0IGRlbGlnaHRmdWwgY29ja3RhaWwgdGhhdCdzIGtub3duIGZvciBpdHMgemVzdHkgYW5kIHRhbmd5IGZsYXZvcnMuIEEgcGVyZmVjdCBiYWxhbmNlIG9mIHN3ZWV0LCBzb3VyLCBhbmQgc2FsdHkgZmxhdm9ycywgbWFrZSBNYXJnYXJpdGEgYW4gZXhjZWxsZW50IGNob2ljZSB0byBjZWxlYnJhdGUgaGFwcGluZXNzLiIsInVybCI6Imh0dHA6Ly9pbWFnZS5zYW1wbGUifQ==";

    // If the path starts with a slash, remove it
    // if (testEncoded.startsWith('/')) {
    //     testEncoded = testEncoded.substring(1);
    // }
    // const decodedJson: UserData = JSON.parse(atob(testEncoded));

    const shareImageUrl = async () => {
        try {
            // 공유하려는 이미지의 URL
            const imageUrl = decodedJson.downloadUrl;
            console.log(`image url = ${imageUrl}`)

            if (!navigator.canShare || !navigator.canShare({url: imageUrl})) {
                alert('Your browser does not support sharing URLs.');
                return;
            }

            // URL 공유
            await navigator.share({
                url: imageUrl,
                title: 'Share Image',
                text: 'Check out this image!',
            });

            console.log('Image URL shared successfully');
        } catch (error) {
            console.error('Error sharing the image URL:', error);
        }
    };

    const downloadImage = () => {
        const filename = decodedJson.downloadUrl.split('/').pop()?.split('?')[0] ?? 'image.png';

        // 다운로드 링크 생성
        const link = document.createElement('a');
        link.href = decodedJson.downloadUrl;
        link.download = filename;
        link.click();
    };

    return (
        <div className={"photo-card-container"}>
            <div className={"photo-card-content"}>
                <header>
                    <h2>Doosan Robotics & GPT in</h2>
                    <h1>Automate</h1>
                </header>
                <main className={"main-content"}>
                    <section className={"mood"}>
                        <p className={"mood-title"}>Your mood seems like</p>
                        <div className={"data mood-data"}>
                            {decodedJson.feel === 'happy' && <Chip className={"happy"} label={"#Happy"}/>}
                            {decodedJson.feel === 'neutral' && <Chip className={"neutral"} label={"#Neutral"}/>}
                            {decodedJson.feel === 'surprised' && <Chip className={"surprised"} label={"#Surprised"}/>}
                            {decodedJson.feel === 'angry' && <Chip className={"angry"} label={"#angry"}/>}
                            {decodedJson.feel === 'disgust' && <Chip className={"disgusted"} label={"#Disgusted"}/>}
                            {decodedJson.feel === 'fearful' && <Chip className={"fearful"} label={"#Fearful"}/>}
                            {decodedJson.feel === 'sad' && <Chip className={"sad"} label={"#Sad"}/>}
                            <div className={"emoji"}>
                                {decodedJson.feel === 'happy' && <Happy/>}
                                {decodedJson.feel === 'neutral' && <Neutral/>}
                                {decodedJson.feel === 'surprised' && <Surprised/>}
                                {decodedJson.feel === 'angry' && <Angry/>}
                                {decodedJson.feel === 'disgust' && <Disgust/>}
                                {decodedJson.feel === 'fearful' && <Fearful/>}
                                {decodedJson.feel === 'sad' && <Sad/>}
                            </div>
                        </div>
                    </section>
                    <Divider />
                    <section className={"cocktail"}>
                        <p className={"cocktail-name"}>
                            Cocktail for you is... <br /><strong>{decodedJson.cocktail}</strong>
                        </p>
                        <p className={"cocktail-desc"}>{decodedJson.recommend}</p>
                        <div className={"cocktail-image"}>
                            <img src={getEmotionImage(decodedJson.feel)} alt="User Cocktail Image" />
                        </div>
                    </section>
                    <Divider />
                    {/*<section className={"music"}>*/}
                    {/*    <p className={"music-is"}>Song for you is...</p>*/}
                    {/*    <div className={"music-info-box"}>*/}
                    {/*        <div className={"album-cover"}>*/}
                    {/*            <img src={AlbumCover} alt="Music album cover" />*/}
                    {/*        </div>*/}
                    {/*        <div className={"music-info"}>*/}
                    {/*            <p><strong>Can't Stop the Feeling</strong></p>*/}
                    {/*            <p>Justin Timberlake</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                </main>

                <h3 className={"ci-logo"}><CiLogo /></h3>
                <div className={"face-photo"}>
                    {decodedJson.downloadUrl ? <img src={decodedJson.downloadUrl} alt="User photo" />
                        :
                        <div className={"loading-picture"}>
                            <p>Loading your picture...</p>
                            <CircularProgress />
                        </div>
                    }
                </div>
                <div className={"button-box"}>
                    <button className={"download-btn"} onClick={downloadImage}><IconDownload/></button>
                    <button className={"export-btn"} onClick={shareImageUrl}><IconExport/></button>
                </div>
            </div>
        </div>

    )
}

export default PhotoCard;

