import React from 'react';
import './assets/styles/styles.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PhotoCard from "./PhotoCard";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<PhotoCard/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

